@import '../../styles/global.styles';

// ************* NAVBAR COMPONENT STYLES ************* //
.navbar {
  // border-bottom: 1px solid $grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 0 2rem;

  // INNER CONTAINER SECTION
  .innerContainer {
    // width: $bp-xl;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // LOGO
    .logoContainer {

      .logoLink {
        text-decoration: none;

        .logo {
          color: $black;
          text-transform: uppercase;
          font-family: $primary-font;
          font-size: 3rem;
          font-weight: 900;
  
          &:hover {
            color: $soft-orange;
          }
        }
      }
    }

    // LINKS
    .linksContainer {

      .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .link {
          color: $dark-grey;
          text-decoration: none;
          font-family: $secondary-font;
          font-size: 1.6rem;
          font-weight: 700;
          margin-left: 2rem;
          
          &:hover {
            color: $soft-orange;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// ************* MEDIA QUERIES ************* //
@media screen and (min-width: $bp-sm) {
  .navbar {
    
  }
}

@media screen and (min-width: $bp-md) {
  .navbar {

  }
}

@media screen and (min-width: $bp-lg) {
  .navbar {
    margin: 0 4rem;

    // INNER CONTAINER SECTION
    .innerContainer {

      // LINKS
      .linksContainer {
        .links {
          .link {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $bp-xl) {
  .navbar {
    padding: 2rem 0;

    .innerContainer {

    }
  }
}