// *************************** FONT VARIABLES *************************** //
// $primary-font: 'Inter', sans-serif;
// $secondary-font: 'Roboto', sans-serif;
// $tertiary-font: 'Nunito', sans-serif;

$primary-font: 'Inter', sans-serif;
$secondary-font: 'Nunito', sans-serif;
$tertiary-font: 'Roboto', sans-serif;

// *************************** COLOR VARIABLES *************************** //

$white:               #FFFFFF;
$black:               #050500;
$dark-grey:           #878789;
$light-grey:          #F2F2F2;
$grey:                #DBDDE3;

$success:             #5C9448;
$danger:              #FF4C4C;
$warning:             #d49424;

$stoplight-yellow:    #FAEE12;
$stoplight-red:       #EC320D;

$strong-blue:         #3082E2;
$light-blue:          #E0F3FF;
$navy-blue:           #253544;
$bright-blue:         #5CD0E5;
$off-blue:            #333C50;

$dark-teal:           #1A8C87;
$light-teal:          #A2DBD8;

$stoplight-green:     #2ADA51;
$jungle-green:        #75D884;

$strong-orange:       #FA4A00;
$soft-orange:         #F8715F;
$bright-orange:       #F75110;
$light-orange:        #FB5A14;

$flamingo-pink:       #FAB3BE;

// *************************** MEDIA QUERY VARIABLES *************************** //

$bp-sm: 500px;
$bp-md: 800px;
$bp-lg: 1200px;
$bp-xl: 1800px;
$bp-xxl: 2400px;


// *************************** GLOBAL SETTINGS *************************** //
html {
  font-size: 62.5%;
}

body {
  font-size: 1.5rem;
  font-family: $primary-font;
}