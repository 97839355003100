@import '../../styles/global.styles';

// ************* FOOTER COMPONENT STYLES ************* //
.footer {
  // border-top: 1px solid $grey;
  padding: 4rem 2rem;
  margin: 0 2rem;

  // INNER CONTAINER SECTION
  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    // LOGO CONTAINER
    .logoContainer {
      display: flex;
      justify-content: center;

      .logoLink {
        text-decoration: none;

        .logo {
          color: $black;
          text-transform: uppercase;
          font-family: $primary-font;
          font-size: 3rem;
          font-weight: 900;
  
          &:hover {
            color: $soft-orange;
          }
        }
      }
    }

    // CONTENT CONTAINER
    .contentContainer {
      display: flex;
      justify-content: center;

      .text {
        font-family: $secondary-font;
        color: $dark-grey;
        text-align: center;
        font-weight: 600;
      }
    }

    // LINKS CONTAINER 
    .linksContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;

      .link {
        margin: 0 1rem;
        color: $black;
        font-family: $primary-font;
        font-size: 1.5rem;
        font-weight: 800;
        text-decoration: none;

        &:hover {
          color: $soft-orange;
        }
      }
    }

    // ICONS CONTAINER
    .iconsContainer {
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .github {
        color: $black;
        margin: 0 1rem;

        &:hover {
          color: $strong-blue;
        }
      }

      .linkedin {
        color: $black;
        margin: 0 1rem;

        &:hover {
          color: $strong-blue;
        }
      }
    }

    // COPYRIGHT CONTAINER
    .copyrightContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      .copyright {
        font-family: $primary-font;
        color: $dark-grey;
        font-size: 1.3rem;
      }
    }
  }

}

// ************* MEDIA QUERIES ************* //
@media screen and (min-width: $bp-sm) {
  .footer {
    
  }
}

@media screen and (min-width: $bp-md) {
  .footer {

  }
}

@media screen and (min-width: $bp-lg) {
  .footer {
    margin: 0 4rem;
    
    // INNER CONTAINER SECTION
    .innerContainer {
      max-width: $bp-xl;

      // LOGO SECTION
      .logoContainer {
        display: flex;
        justify-content: flex-start;
      }

      // CONTENT SECTION
      .contentContainer {
        display: flex;
        justify-content: flex-start;

        .text {
          text-align: justify;
          font-size: 1.6rem;
        }
      }

      // LINKS CONTAINER 
      .linksContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .link {
          font-size: 1.6rem;
        }
      }

      
      // ICONS CONTAINER
      .iconsContainer {
        display: flex;
        justify-content: flex-start;
        font-size: 2.4rem;
      }

      // COPYRIGHT CONTAINER
      .copyrightContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: $bp-xl) {
  .footer {
    padding: 5rem 0;
  }
}