@import '../../styles/global.styles';

// ************* NOT FOUND PAGE COMPONENT STYLES ************* //
.notFoundPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 4.4rem;
    font-weight: 900;
  }

  .linkContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .link {
      color: $strong-blue;
      margin-right: 1rem;
      font-size: 1.4rem;
    }
    
    .linkText {
      font-size: 1.4rem;
    }
  }
}

// ************* HOME PAGE COMPONENT STYLES ************* //
@media screen and (min-width: $bp-sm) {
  .notFoundPage {
    .title {
      font-size: 5.2rem;
    }
  
    .linkContainer {
      .link {
        font-size: 1.6rem;
      }
      
      .linkText {
        font-size: 1.6rem;
      }
    } 
  }
}

@media screen and (min-width: $bp-md) {
  .notFoundPage {

  }
}

@media screen and (min-width: $bp-lg) {
  .notFoundPage {

  }
}