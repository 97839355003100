@import '../../styles/global.styles';

// ************* HOME LANDING COMPONENT STYLES (HOMEPAGE.JS) ************* //
.homeLanding {
  // border: 1px solid $black;
  // background: $soft-orange;
  height: 100vh;
  // border-radius: 0 0 50% 50%/60px;
  // border-radius: 0 300% 300% 300%/300rem;
  // border-radius: 0 300% 0 300%/300rem;

  // INNER CONTAINER
  .innerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 2rem;

    // TOP CONTAINER
    .topContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80%;
      padding: 4rem;

      .subtitle {
        color: $dark-grey;
        font-family: $primary-font;
        font-size: 1.4rem;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
      }

      .title {
        color: $black;
        font-family: $primary-font;
        font-size: 5.2rem;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
      }

      .contentContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .contentText {
          color: $dark-grey;
          font-family: $primary-font;
          font-size: 1.4rem;
          font-weight: 500;
          text-align: center;
          text-transform: uppercase;

          .nameText {
            color: $black;
            font-weight: 700;
          }
        }
      }
    }

    // BOTTOM CONTAINER
    .bottomContainer {
      height: 20%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      .portfolioLink {
        color: $black;
        font-family: $primary-font;
        font-size: 1.6rem;
        font-weight: 600;

        &:hover {
          color: $soft-orange;
        }
      }
    }
  }

}

// ************* MEDIA QUERIES ************* //
@media screen and (min-width: $bp-sm) {
  .homeLanding {
    
    // INNER CONTAINER
    .innerContainer {
      // TOP CONTAINER
      .topContainer {
        .title {
          font-size: 7.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-md) {
  .homeLanding {

    // INNER CONTAINER
    .innerContainer {
      // TOP CONTAINER
      .topContainer {

        .contentContainer {
          .contentText {
            .nameText {
              color: $black;
              font-weight: 700;
              font-size: 1.6rem;
            }
          }
        }
      }

      // BOTTOM CONTAINER
      .bottomContainer {
        .portfolioLink {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-lg) {
  .homeLanding {

    // INNER CONTAINER
    .innerContainer {
      margin: 0 4rem;

      // TOP CONTAINER
      .topContainer {
        .subtitle {
          font-size: 1.5rem;
        }

        .title {
          font-size: 8.8rem;
        }

        .contentContainer {
          .contentText {
            font-size: 1.5rem;
          }
        }
      }

      // BOTTOM CONTAINER
      .bottomContainer {
        .portfolioLink {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-xl) {
  .homeLanding {

  }
}