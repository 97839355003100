@import '../styles/global.styles';

// ************* APP COMPONENT STYLES ************* //
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex-grow: 1;
  }
}